import { UserAddressCardContainer } from "./user-address-card.styles";

import AddressCardDialog from "../acct-dialogs/address-card-dialog.component";

function UserAddressCard({ userAddress, setError, setSuccess }) {
  const {
    first_name,
    last_name,
    phone,
    address1,
    address2,
    city,
    state,
    country,
    zip,
  } = userAddress;
  return (
    <UserAddressCardContainer>
      <p>
        <span>Name:</span> {first_name} {last_name}
      </p>
      <p>
        <span>Phone Number:</span> {phone}
      </p>
      <p>
        <span>Address:</span> {address1}
      </p>
      {address2 && (
        <p>
          <span>Address Details (Optional):</span> {address2}
        </p>
      )}
      <p>
        <span>City:</span> {city}
      </p>
      <p>
        <span>State:</span> {state.label}
      </p>
      <p>
        <span>Country:</span> {country.label}
      </p>
      <p>
        <span>Zip Code:</span> {zip}
      </p>
      <AddressCardDialog
        userAddress={userAddress}
        setError={setError}
        setSuccess={setSuccess}
      />
    </UserAddressCardContainer>
  );
}

export default UserAddressCard;
