import React from "react";

import { StyledError } from "./error.styles";

function Error({ error }) {
  console.log(error);
  return (
    <StyledError>
      Error: {error.code} - {error.message}
    </StyledError>
  );
}

export default Error;
