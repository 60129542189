import { createSelector } from "reselect";

const selectProductsReducer = (state) => state.products;

export const selectProducts = createSelector(
  [selectProductsReducer],
  (productsSlice) => productsSlice.products
);

//receives an array, converts to object...
const order = ["shirts", "hoodies", "mugs", "totes", "hats"];

export const selectProductsMap = createSelector(
  [selectProducts],
  (products) => {
    const result = {};
    order.forEach((key) => {
      result[key] = [];
    });
    products.forEach((item) => {
      const tags = item.tags.map((tag) => tag.toLowerCase());
      tags.forEach((tag) => {
        if (tag === "t-shirts") {
          result["shirts"].push(item);
        } else if (tag === "hoodies") {
          result["hoodies"].push(item);
        } else if (tag === "mugs") {
          result["mugs"].push(item);
        } else if (tag === "totes") {
          result["totes"].push(item);
        } else if (tag === "hats") {
          result["hats"].push(item);
        }
      });
    });
    return result;
  }
);

export const selectProductsIsLoading = createSelector(
  [selectProductsReducer],
  (productsSlice) => productsSlice.isLoading
);
