import { useState, useEffect } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import Select from "react-select";

import { useMediaQuery } from "react-responsive";
import { data } from "iso-3166-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { addShippingAddressToUserDoc } from "../../utils/firebase.utils";

import "./acct-dialogs.styles.scss";

// default form fields
const DefaultFormFields = {
  email: "",
  phone: "",
  country: { value: "", label: "" },
  first_name: "",
  last_name: "",
  address1: "",
  address2: "",
  city: "",
  state: { value: "", label: "" },
  zip: "",
};

// country options for react-select
const countries = [
  { value: "US", label: "U.S.A" },
  { value: "CA", label: "Canada" },
];

const wait = () => new Promise((resolve) => setTimeout(resolve, 1000));

function ShippingDialog({ currentUser, setSuccess, setError }) {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const [formFields, setFormFields] = useState(DefaultFormFields);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [statesOrProvinces, setStatesOrProvinces] = useState(null);
  const close = <FontAwesomeIcon icon={faXmark} />;
  const penToSquare = <FontAwesomeIcon icon={faPenToSquare} />;

  const handleInfoChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters from the phone number
    const cleaned = phoneNumber.replace(/\D/g, "");

    // Check if the phone number has 11 digits
    if (cleaned.length === 11) {
      // Format the 11-digit phone number as: 1 (123) 456-7890
      const regex = /^(\d)(\d{3})(\d{3})(\d{4})$/;
      const formatted = cleaned.replace(regex, "1 ($2) $3-$4");
      return formatted;
    } else {
      // Format the 10-digit phone number as: (123) 456-7890
      const regex = /^(\d{3})(\d{3})(\d{4})$/;
      const formatted = cleaned.replace(regex, "($1) $2-$3");
      return formatted;
    }
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;

    // Format the phone number
    const formattedValue = formatPhoneNumber(value);

    setFormFields({ ...formFields, [name]: formattedValue });
  };

  const handleZipChange = (e) => {
    const { name, value } = e.target;

    // Remove any spaces from the input value
    const formattedValue = value.replace(/\s/g, "");

    // Use regex to add a space after the third character
    const regex = /^([A-Za-z]\d[A-Za-z])\s?(\d[A-Za-z]\d)$/;
    if (regex.test(formattedValue)) {
      setFormFields({
        ...formFields,
        [name]: formattedValue.replace(regex, "$1 $2"),
      });
    } else {
      setFormFields({ ...formFields, [name]: formattedValue });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(null);

    const handleAddShippingAddress = async (formFields) => {
      const message = await addShippingAddressToUserDoc(formFields);

      if (message instanceof Error) {
        setError(message);
      } else {
        setSuccess(message);
        setFormFields(DefaultFormFields);
        setSelectedCountryOption(null);
        setSelectedStateOption(null);
        setStatesOrProvinces(null);
      }
    };

    const formFieldsCopy = { ...formFields };

    handleAddShippingAddress(formFieldsCopy);

    wait().then(() => setOpen(false));
  };

  const {
    email,
    phone,
    country,
    first_name,
    last_name,
    address1,
    address2,
    city,
    state,
    zip,
  } = formFields;

  useEffect(() => {
    if (currentUser) {
      setFormFields({ ...formFields, email: currentUser.email });
    }
  }, [currentUser]);

  //* when user selects a country, update the statesOrProvinces array and set.
  useEffect(() => {
    if (selectedCountryOption?.value !== "" && selectedCountryOption) {
      setFormFields({
        ...formFields,
        country: selectedCountryOption,
        state: "",
      });
      const countryRegions = data[selectedCountryOption.value].sub;
      let statesArray = [];

      const regions = Object.values(countryRegions);
      for (const { name } of regions) {
        statesArray.push({ value: name, label: name });
      }
      setStatesOrProvinces(statesArray);
      setSelectedStateOption(null);
    }
  }, [selectedCountryOption]);

  // * when user selects a state, update the formFields state.
  useEffect(() => {
    if (selectedStateOption) {
      const countryRegions = data[selectedCountryOption.value].sub;
      const regions = Object.entries(countryRegions);
      for (const [code, { name }] of regions) {
        if (name === selectedStateOption.label) {
          setFormFields({
            ...formFields,
            state: { value: code, label: selectedStateOption.label },
          });
        }
      }
    }
  }, [selectedStateOption]);

  // styles for react-select
  const selectColorStyles = {
    control: (styles, { isFocused, isSelected, isActive }) => ({
      ...styles,
      marginBottom: "0px",
      backgroundColor: "white",
      border: "1px solid var(--accent-text)",
      borderColor:
        isFocused || isSelected || isActive ? "var(--accent-text)" : "none",
      boxShadow:
        isFocused || isSelected || isActive
          ? "0 0 0 1px var(--accent-text)"
          : "none",
      outline:
        isFocused || isSelected || isActive ? "1px solid transparent" : "none",
      "&:hover": { border: "1px solid var(--accent-text)" },
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected ? "var(--light-bg)" : "white",
      color: isSelected ? "var(--accent-text)" : "black",
      "&:hover": {
        backgroundColor: isFocused ? "var(--light-bg)" : "none",
        color: isFocused ? "var(--accent-text)" : "black",
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "var(--accent-text)",
      fontSize: isMobile ? "var(--fs-300)" : "var(--fs-400)",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "black",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "var(--accent-text)",
      fontSize: isMobile ? "var(--fs-300)" : "var(--fs-400)",
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: state.selectProps.menuPlacement === "top" ? "unset" : "0px",
      marginBottom: state.selectProps.menuPlacement === "top" ? "0px" : "unset",
    }),
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger className="trigger">Add {penToSquare}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="overlay" />
        <Dialog.Content className="dialog-content">
          <h2>Edit Shipping Address</h2>
          <form onSubmit={handleSubmit}>
            <input
              placeholder="First Name"
              label="First Name"
              aria-label="First Name"
              required
              type="text"
              onChange={handleInfoChange}
              name="first_name"
              value={first_name}
            />
            <input
              placeholder="Last Name"
              label="Last Name"
              aria-label="Last Name"
              required
              type="text"
              onChange={handleInfoChange}
              name="last_name"
              value={last_name}
            />
            <input
              placeholder="Phone Number"
              label="Phone Number"
              aria-label="Phone Number"
              required
              type="tel"
              onChange={handlePhoneChange}
              name="phone"
              value={phone}
            />
            <input
              placeholder="Address"
              label="Address"
              aria-label="Address"
              required
              type="text"
              onChange={handleInfoChange}
              name="address1"
              value={address1}
            />
            <input
              placeholder="Apartment, suite, etc. (optional)"
              label="Apartment, suite, etc. (optional)"
              aria-label="Apartment, suite, etc. (optional)"
              type="text"
              onChange={handleInfoChange}
              name="address2"
              value={address2}
            />
            <input
              placeholder="City"
              label="City"
              aria-label="City"
              required
              type="text"
              onChange={handleInfoChange}
              name="city"
              value={city}
            />

            <Select
              styles={selectColorStyles}
              label="Country"
              aria-label="Country"
              defaultValue={selectedCountryOption}
              onChange={setSelectedCountryOption}
              options={countries}
              required
              name="country"
              value={country.value === "" ? null : country}
              placeholder={
                selectedCountryOption && selectedCountryOption.value !== ""
                  ? selectedCountryOption.label
                  : "Select Country..."
              }
              tabIndex={7}
            />

            {formFields.country.value === "US" && (
              <Select
                styles={selectColorStyles}
                label="State"
                aria-label="State"
                key={selectedStateOption}
                defaultValue={selectedStateOption}
                onChange={setSelectedStateOption}
                options={statesOrProvinces}
                required
                name="state"
                value={state}
                placeholder={
                  selectedStateOption ? selectedStateOption.label : "State..."
                }
                menuPlacement="top"
                tabIndex={8}
              />
            )}

            {formFields.country.value === "CA" && (
              <Select
                styles={selectColorStyles}
                label="Province"
                aria-label="Province"
                key={formFields.country}
                defaultValue={selectedStateOption}
                onChange={setSelectedStateOption}
                options={statesOrProvinces}
                required
                name="state"
                value={state}
                placeholder={
                  selectedStateOption
                    ? selectedStateOption.label
                    : "Province..."
                }
                menuPlacement="top"
                tabIndex={9}
              />
            )}

            {formFields.country.value && (
              <input
                placeholder={
                  selectedCountryOption?.value === "US"
                    ? "Zip Code"
                    : "Postal Code"
                }
                label={
                  selectedCountryOption?.value === "US"
                    ? "Zip Code"
                    : "Postal Code"
                }
                aria-label={
                  selectedCountryOption?.value === "US"
                    ? "Zip Code"
                    : "Postal Code"
                }
                required
                type="text"
                onChange={handleZipChange}
                name="zip"
                value={zip}
              />
            )}

            <button aria-label="Submit" className="submit-button" type="submit">
              Add Address
            </button>
          </form>
          <Dialog.Close className="close-button">{close}</Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default ShippingDialog;
