import { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { register } from "swiper/element/bundle";

import {
  onAuthStateChangedListener,
  createUserDocFromAuth,
} from "./utils/firebase.utils";

import { getDoc } from "firebase/firestore";

import { getAll } from "./store/products/products.actions";
import { setCurrentUser } from "./store/user/user.reducer";
import { selectProducts } from "./store/products/products.selector";
import { setDiscount } from "./store/cart/cart.reducer";

import SwirlSpinner from "./components/swirl-spinner/swirl-spinner.component";

import Account from "./components/routes/account/account.component.jsx";

const Navigation = lazy(() =>
  import("./components/routes/navigation/navigation.component.jsx")
);

const Footer = lazy(() => import("./components/footer/footer.component"));

const Home = lazy(() => import("./components/routes/home/home.component"));
const Authentication = lazy(() =>
  import("./components/routes/authentication/authentication.component")
);
const Cart = lazy(() => import("./components/routes/cart/cart.component"));
const Checkout = lazy(() =>
  import("./components/routes/checkout/checkout.component")
);
const TrackingInfo = lazy(() =>
  import("./components/routes/tracking-info/tracking-info.component")
);
const Admin = lazy(() => import("./components/routes/admin/admin.component"));
const Category = lazy(() =>
  import("./components/routes/category/category.component")
);
const ProductPage = lazy(() =>
  import("./components/routes/product-page/product-page.component")
);
const Policies = lazy(() =>
  import("./components/policies/policies.component.jsx")
);

function App() {
  register();
  const dispatch = useDispatch();
  const printifyProducts = useSelector(selectProducts);

  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener(async (user) => {
      if (user) {
        if (!user.displayName) {
          const userDocRef = await createUserDocFromAuth(dispatch, user);
          const userSnapshot = await getDoc(userDocRef);
          dispatch(
            setCurrentUser({
              ...userSnapshot.data(),
              displayName: userSnapshot.data().displayName,
            })
          );
        } else {
          dispatch(setCurrentUser(user));
        }
      } else {
        dispatch(setCurrentUser(null));
        dispatch(setDiscount(0));
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (printifyProducts.length > 0) return;
    dispatch(getAll());
  }, []);

  useEffect(() => {
    document.title = "Mellybean | Shop";
  }, []);

  return (
    <PayPalScriptProvider
      options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
    >
      <Suspense fallback={<SwirlSpinner />}>
        <Navigation />
        <Routes>
          <Route index element={<Home />} />
          <Route path="auth" element={<Authentication />} />
          <Route path="account" element={<Account />} />
          <Route path="cart" element={<Cart />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="policies" element={<Policies />} />
          <Route path="tracking-info/:orderID" element={<TrackingInfo />} />
          <Route path="admin/*" element={<Admin />} />
          <Route path=":category" element={<Category />} />
          <Route path="/product/:productID" element={<ProductPage />} />
        </Routes>
        <Footer />
      </Suspense>
    </PayPalScriptProvider>
  );
}

export default App;
