export const getPrintifyOrderById = async (orderID) => {
  try {
    const response = await fetch(
      `/.netlify/functions/printify-get-order?order_id=${orderID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getPrintifyProductById = async (productID) => {
  try {
    const response = await fetch(
      `/.netlify/functions/printify-get-product?product_id=${productID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
        },
        timeout: 60000,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllPrintifyProducts = async () => {
  try {
    const response = await fetch(
      `/.netlify/functions/printify-get-all-products`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
        },
        timeout: 30000,
      }
    );
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUnpublishedProducts = async () => {
  try {
    const response = await fetch(
      `/.netlify/functions/printify-get-unpublished-products`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
        },
        timeout: 30000,
      }
    );
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const publishAllPrintifyProducts = async () => {
  try {
    const response = await fetch(`/.netlify/functions/printify-publish-all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
      timeout: 90000,
    });
    const data = await response.json();
  } catch (error) {
    console.log(error);
  }
};

export const getDefaultVariant = (product) => {
  return product.variants.filter((variant) => variant.is_default === true);
};

//* get merchant selected image url using product.images and defVarId...
export const getMerchantSelectedImgUrl = (productImages, defaultVariantId) => {
  // loop through product images array
  for (let i = 0; i < productImages.length; i++) {
    const imageObj = productImages[i];

    // loop through image variant ids
    for (let j = 0; j < imageObj.variant_ids.length; j++) {
      const imgId = imageObj.variant_ids[j];

      if (
        imgId === defaultVariantId &&
        imageObj.is_default === true &&
        imageObj.is_selected_for_publishing === true
      ) {
        return imageObj.src;
      }
    }
  }
};

//* Get available color swatches as well as hex and option codes...
export const getAvailableColorsAndDetails = (product, productOptions) => {
  // Gather default variant colors
  const availableVariants = product.variants.filter(
    (variant) => variant.is_enabled === true
  );

  // result will need to be flattened after processing
  let availableColorsRaw = [];
  let availableColors = [];

  //loop through availableVariants
  for (let h = 0; h < availableVariants.length; h++) {
    const availableVar = availableVariants[h];
    // loop through availableVariants options array
    for (let i = 0; i < availableVar.options.length; i++) {
      const obj1Option = availableVar.options[i];

      // loop through product options array
      for (let j = 0; j < productOptions.length; j++) {
        const obj2Option = productOptions[j];

        // loop through product's values array for each option
        for (let k = 0; k < obj2Option.values.length; k++) {
          const obj2Value = obj2Option.values[k];

          // check if product's value matches availableVariant's option
          if (obj2Value.id === obj1Option) {
            // do something if there is a match
            if (obj2Value.colors?.length > 0) {
              if (!availableColorsRaw.includes(obj2Value.colors)) {
                availableColorsRaw.push(obj2Value.colors);
                availableColors.push({
                  id: obj2Value.id,
                  title: obj2Value.title,
                  code: obj2Value.colors[0],
                });
              }
            }
          }
        }
      }
    }
  }
  return availableColors;
};

//* Get high contrast color for dynamic text/backgrounds
export const getContrastColor = (color) => {
  var hex = color.replace("#", "");
  var r = parseInt(hex.substr(0, 2), 16);
  var g = parseInt(hex.substr(2, 2), 16);
  var b = parseInt(hex.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#4e4e52" : "#ffffff";
};

//* Get variants by color.id, product and options...
export const getVariantsByColorId = (colorId, product) => {
  // Gather default variants
  const availableVariants = product.variants.filter(
    (variant) => variant.is_enabled === true
  );

  let variantsWithSharedColor = [];

  //loop through availableVariants
  for (let h = 0; h < availableVariants.length; h++) {
    const availableVar = availableVariants[h];
    // loop through availableVariants options array
    for (let i = 0; i < availableVar.options.length; i++) {
      const obj1Option = availableVar.options[i];

      // loop through product options array
      for (let j = 0; j < product.options.length; j++) {
        const obj2Option = product.options[j];

        // loop through product's values array for each option
        for (let k = 0; k < obj2Option.values.length; k++) {
          const obj2Value = obj2Option.values[k];

          // check if product's value matches availableVariant's option
          if (obj2Value.id === obj1Option) {
            // do something if there is a match
            if (obj2Value.colors?.length > 0) {
              if (obj2Value.id === colorId) {
                variantsWithSharedColor.push(availableVar);
              }
            }
          }
        }
      }
    }
  }

  return variantsWithSharedColor;
};

//* Get user selected variant...
export const getUserSelectedVariant = (
  selectedVarFamily,
  selectedAttributes
) => {
  let userSelectedVar;
  //loop through selectedVarFamily...
  for (let i = 0; i < selectedVarFamily.length; i++) {
    let hits = 0;
    const selectedVar = selectedVarFamily[i];

    //loop through selectedVar's options...
    for (let j = 0; j < selectedVar.options.length; j++) {
      const option = selectedVar.options[j];

      //loop through selectedAttribute
      for (let k = 0; k < Object.keys(selectedAttributes).length; k++) {
        const selectedOption = Object.values(selectedAttributes)[k];
        if (option === selectedOption) {
          hits++;
        }
      }
    }
    if (hits === selectedVar.options.length) {
      userSelectedVar = selectedVar;
    }
  }

  return userSelectedVar;
};

//* Get index numbers for size and color...
export const getColorAndSizOptionIndex = (productOptions) => {
  let colorAndSizeIndexes = {
    colorIndex: null,
    sizeIndex: null,
  };
  productOptions.map((option, i) => {
    if (option.type === "color") {
      colorAndSizeIndexes.colorIndex = i;
    }
    if (option.type === "size") {
      colorAndSizeIndexes.sizeIndex = i;
    }
  });
  return colorAndSizeIndexes;
};

//* Get available sizes...
export const getAvailableSizes = (selectedVarFamily, productOptions) => {
  let availableSizes = [];

  //loop through selectedVarFamily...
  for (let h = 0; h < selectedVarFamily.length; h++) {
    const selectedVar = selectedVarFamily[h];

    // loop through selectedVar's options array
    for (let i = 0; i < selectedVar.options.length; i++) {
      const obj1Option = selectedVar.options[i];

      // loop through product options array
      for (let j = 0; j < productOptions.length; j++) {
        const obj2Option = productOptions[j];

        if (obj2Option.type === "size") {
          // loop through product's values array for each option
          for (let k = 0; k < obj2Option.values.length; k++) {
            const obj2Value = obj2Option.values[k];

            // check if product's value matches selectedVar's option
            if (obj2Value.id === obj1Option) {
              // do something if there is a match
              availableSizes.push(obj2Value);
            }
          }
        }
      }
    }
  }
  return availableSizes;
};

// format price
export const formatPrice = (price) => {
  const formattedPrice = (price / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formattedPrice;
};

//* Format date...
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  });
  return formattedDate;
};

//* Get product id from variant sku...
export const getProductIdFromVariantSku = (sku, products) => {
  let productId;
  products.map((product) => {
    product.variants.map((variant) => {
      if (variant.sku === sku) {
        productId = product.id;
      }
    });
  });
  return productId;
};

//* Get product variant by sku...
export const getProductVariantBySku = (product, sku) => {
  let variantX = [];
  product.variants.map((variant) => {
    if (variant.sku === sku) {
      variantX.push(variant);
    }
  });
  return variantX;
};

//* set image to represent selected color,
//* reusing getMerchantSelectedImgUrl with id from the selected color's varFamily...

export const findImageForSelectedColorFamily = (
  selectedVarFamily,
  productImages
) => {
  const familyImg = selectedVarFamily.filter((v) => v.is_available);
  if (
    (familyImg[0]?.id &&
      getMerchantSelectedImgUrl(productImages, familyImg[0].id) != undefined) ||
    null
  ) {
    return getMerchantSelectedImgUrl(productImages, familyImg[0].id);
  } else {
    return null;
  }
};

//* Get all images of selected variant...
export const getSelectedVariantImages = (selectedVariant, productImages) => {
  let selectedVariantImages = [];
  productImages.map((image) => {
    if (
      image.variant_ids.includes(selectedVariant.id) &&
      image.is_selected_for_publishing === true
    ) {
      selectedVariantImages.push(image);
    }
  });
  return selectedVariantImages;
};

export const getPrintifyShippingOptions = async (shippingRequestObject) => {
  try {
    const response = await fetch(`/.netlify/functions/printify-get-shipping`, {
      method: "POST",
      body: JSON.stringify(shippingRequestObject),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
      timeout: 60000,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const publishProductByID = async (productID) => {
  try {
    const response = await fetch(
      `/.netlify/functions/printify-publish-product`,
      {
        method: "POST",
        body: JSON.stringify({ id: productID }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
        },
        timeout: 60000,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const failProductByID = async (productID) => {
  try {
    const response = await fetch(`/.netlify/functions/printify-fail-product`, {
      method: "POST",
      body: JSON.stringify({ id: productID }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
      timeout: 60000,
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createPrintifyOrder = async (orderObject) => {
  try {
    const response = await fetch(`/.netlify/functions/printify-create-order`, {
      method: "POST",
      body: JSON.stringify(orderObject),
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
      timeout: 60000,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const sendOrderConfirmationEmail = async (
  emailString,
  recipient,
  first_name,
  orderStatus
) => {
  const response = await fetch(`/.netlify/functions/send-order-confirmation`, {
    method: "POST",
    body: JSON.stringify({
      emailString,
      recipient,
      first_name,
      orderStatus,
      contentType: "text/html",
    }),
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
    },
  });

  const data = await response.json();
  return data;
};

export const sendOrderErrorEmail = async (
  date,
  first_name,
  last_name,
  email,
  error
) => {
  const response = await fetch(`/.netlify/functions/send-order-error-info`, {
    method: "POST",
    body: JSON.stringify({
      date,
      first_name,
      last_name,
      email,
      error,
      contentType: "text/html",
    }),
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
    },
  });

  const data = await response.json();
  return data;
};

export const createTagsFromTitle = (title) => {
  let tags = [];
  title.split(/\s|,/).map((word) => {
    word = word.toLowerCase();
    if (
      word === "tugs" ||
      word === "chuck" ||
      word === "butternut" ||
      word === "melly" ||
      word === "nara" ||
      word === "hetty" ||
      word === "tee" ||
      word === "mug" ||
      word === "tote" ||
      word === "shirt" ||
      word === "hoodie" ||
      word === "cap" ||
      (word === "hat" && !tags.includes(word))
    ) {
      tags.push(word);
    }
  });
  return tags;
};

export const getProductsUsingTags = (productsMap, tags) => {
  let filteredProducts = [];
  Object.entries(productsMap).map(([category, categoryArr]) => {
    categoryArr.map((product) => {
      product.title.split(/\s|,/).map((word) => {
        word = word.toLowerCase();
        if (tags.includes(word)) {
          filteredProducts.push({ ...product, category: category });
          return;
        }
      });
    });
  });
  const noDuplicates = Array.from(
    new Set(filteredProducts.map(JSON.stringify)),
    JSON.parse
  );
  return noDuplicates;
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const getRandomProducts = (productsMap) => {
  let products = [];
  Object.entries(productsMap).forEach(([category, categoryArr]) => {
    const productsWithCategory = categoryArr.map((product) => ({
      ...product,
      category: category,
    }));
    products.push(...productsWithCategory);
  });

  const shuffledProducts = shuffleArray(products);
  return shuffledProducts;
};

export const addEmailToList = async (email) => {
  const response = await fetch(`/.netlify/functions/add-email-to-list`, {
    method: "POST",
    body: JSON.stringify({
      email,
    }),
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
    },
  });

  const data = await response.json();
  return data;
};

export const sendCoupon = async (recipient, displayName) => {
  const response = await fetch(`/.netlify/functions/send-coupon`, {
    method: "POST",
    body: JSON.stringify({
      recipient,
      displayName,
    }),
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
    },
  });

  const data = await response.json();
  return data;
};
