import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { updateUserDisplayName, signOutUser } from "../../utils/firebase.utils";

import "./acct-dialogs.styles.scss";

const wait = () => new Promise((resolve) => setTimeout(resolve, 1000));

function DisplayNameDialog({ currentUser, setError, setSuccess }) {
  const navigate = useNavigate();
  const close = <FontAwesomeIcon icon={faXmark} />;
  const penToSquare = <FontAwesomeIcon icon={faPenToSquare} />;
  const [newDisplayName, setNewDisplayName] = useState("");
  const [open, setOpen] = useState(false);

  const handleFirebaseError = (error) => {
    if (checkFirebaseError(error)) {
      signOutUser();
      navigate("/auth", { state: { from: window.location.pathname } });
    }
  };

  const checkFirebaseError = (error) => {
    const errorCode = error.code;
    return errorCode === "auth/requires-recent-login";
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      const handleUpdateDisplayName = async () => {
        const message = await updateUserDisplayName(newDisplayName);
        if (message instanceof Error) {
          setError(message);
          handleFirebaseError(message);
        } else {
          setSuccess(message);
        }
      };
      handleUpdateDisplayName();
      window.scrollTo({ top: 0, behavior: "smooth" });
      wait().then(() => setOpen(false));
    } catch (error) {
      console.error("Error updating display name:", error);
      setError({ code: "0400", message: "Failed to update display name" });
    }
  };

  const handleSetNewDisplayName = (e) => {
    setNewDisplayName(e.target.value);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger className="trigger">Edit {penToSquare}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="overlay" />
        <Dialog.Content className="dialog-content">
          <h2>Update Display Name</h2>
          <p>
            Current Display Name: <span>{currentUser?.displayName}</span>
          </p>

          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="New Display Name"
              value={newDisplayName}
              onChange={(e) => handleSetNewDisplayName(e)}
            />

            <button className="submit-button" type="submit">
              Update Display Name
            </button>
          </form>
          <Dialog.Close className="close-button">{close}</Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default DisplayNameDialog;
