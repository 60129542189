import styled from "styled-components";

export const AccountContainer = styled.div`
  width: clamp(300px, 90%, 600px);
  min-height: calc(100vh - 300px);
  margin: 25px auto;
  border: 2px solid black;
  box-shadow: -3px 3px 0 0 rgba(0, 0, 0, 1);
  padding: 25px;
  height: max-content;

  h1 {
    font-size: 1.5em;
    text-align: center;
    border-bottom: 3px solid var(--primary-color);
    padding-bottom: 10px;
  }

  p {
    font-size: var(--fs-400);
    margin: 0px;
    padding-block: 15px;
    @media (max-width: 480px) {
      margin-right: 55px;
      font-size: var(--fs-300);
    }
  }

  span {
    font-weight: bold;
    color: var(--accent-text);
  }

  .notification {
    background-color: var(--light-bg);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid var(--accent-text);
  }

  .trigger {
    position: absolute;
    top: 15px;
    right: 0px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: var(--fs-400);
    color: var(--accent-text);
    transition: all 0.2s ease-in-out;
    margin-left: auto;

    &:hover {
      color: var(--primary-color);
    }
    @media (max-width: 480px) {
      font-size: var(--fs-300);
    }
  }
`;

export const GoogleUserContainer = styled.div`
  background-color: var(--light-bg);
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid var(--accent-text);
`;

export const EmailContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

export const PasswordContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

export const UsernameContainer = styled.div`
  position: relative;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

export const AddressContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const AddressCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;

  div:first-of-type {
    margin-top: 25px;
    border: 3px solid var(--accent-text);
    &:before {
      position: absolute;
      top: 0px;
      right: -3px;
      transform: translateY(-100%);
      content: "Default Address";
      font-size: var(--fs-400);
      font-weight: bold;
      color: white;
      margin-bottom: 10px;
      padding: 8px 16px;
      background-color: var(--accent-text);
      border-radius: 5px 5px 0% 0% / 5px 5px 0% 0%;
      @media (max-width: 480px) {
        font-size: var(--fs-300);
      }
    }
  }
`;

export const EditButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: var(--fs-400);
  color: var(--accent-text);
  transition: all 0.2s ease-in-out;
  margin-left: auto;

  &:hover {
    color: black;
  }
`;

export const AddressEditButton = styled(EditButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
