import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { updateUserEmail, signOutUser } from "../../utils/firebase.utils";

import "./acct-dialogs.styles.scss";

const wait = () => new Promise((resolve) => setTimeout(resolve, 1000));

function EmailDialog({ currentUser, setError, setSuccess }) {
  const navigate = useNavigate();
  const close = <FontAwesomeIcon icon={faXmark} />;
  const penToSquare = <FontAwesomeIcon icon={faPenToSquare} />;
  const [newEmail, setNewEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [open, setOpen] = useState(false);

  const handleFirebaseError = (error) => {
    if (checkFirebaseError(error)) {
      signOutUser();
      navigate("/auth", { state: { from: window.location.pathname } });
    }
  };

  const checkFirebaseError = (error) => {
    const errorCode = error.code;
    return errorCode === "auth/requires-recent-login";
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(null);
    setIsEmailValid(true);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(newEmail) && newEmail === confirmEmail) {
      try {
        const handleUpdateEmail = async () => {
          const message = await updateUserEmail(newEmail);
          if (message instanceof Error) {
            setError(message);
            handleFirebaseError(message);
          } else {
            setSuccess(message);
          }
        };
        handleUpdateEmail();
        window.scrollTo({ top: 0, behavior: "smooth" });
        wait().then(() => setOpen(false));
      } catch (error) {
        console.error("Error updating email:", error);
        setError({ code: "0200", message: "Failed to update email" });
      }
    } else if (emailPattern.test(newEmail) && newEmail !== confirmEmail) {
      setIsEmailValid(false);
      setError({ code: "0100", message: `Email doesn't match` });
    } else {
      setIsEmailValid(false);
      setError({ code: "0000", message: `Invalid email` });
    }
  };

  const handleSetNewEmail = (e) => {
    setIsEmailValid(true);
    setNewEmail(e.target.value);
  };

  const handleConfirmEmail = (e) => {
    setConfirmEmail(e.target.value);
    if (newEmail === e.target.value) {
      setIsEmailValid(true);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger className="trigger">Edit {penToSquare}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="overlay" />
        <Dialog.Content className="dialog-content">
          <h2>Update Email</h2>
          <p>
            Current Email: <span>{currentUser?.email}</span>
          </p>
          <p>You may be redirected to sign in again in order to update this.</p>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="New Email"
              value={newEmail}
              onChange={(e) => handleSetNewEmail(e)}
            />
            <input
              placeholder="Confirm New Email"
              type="email"
              value={confirmEmail}
              onChange={(e) => handleConfirmEmail(e)}
              className={!isEmailValid ? "error" : ""}
            />
            <button className="submit-button" type="submit">
              Update Email
            </button>
          </form>
          <Dialog.Close className="close-button">{close}</Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default EmailDialog;
