import { createSlice } from "@reduxjs/toolkit";

const addCartItem = (cartItems, productToAdd) => {
  const existingCartItem = cartItems.find(
    (item) => item.sku === productToAdd.sku
  );

  if (existingCartItem) {
    return cartItems.map((item) =>
      item.sku === productToAdd.sku
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );
  }

  return [...cartItems, { ...productToAdd, quantity: 1 }];
};

const removeCartItem = (cartItems, cartItemToRemove) => {
  const existingCartItem = cartItems.find(
    (item) => item.sku === cartItemToRemove.sku
  );

  if (existingCartItem.quantity === 1) {
    return cartItems.filter((item) => item.sku !== cartItemToRemove.sku);
  }

  if (existingCartItem) {
    return cartItems.map((item) =>
      item.sku === cartItemToRemove.sku
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
  }
};

const deleteCartItem = (cartItems, productToDelete) => {
  const existingCartItem = cartItems.find(
    (item) => item.sku === productToDelete.sku
  );

  return cartItems.filter((item) => item.sku !== existingCartItem.sku);
};

export const CART_INITIAL_STATE = {
  cartItems: [],
  isCartOpen: false,
  shippingInfo: { type: null, price: null },
  discount: 0,
  completedOrder: {},
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: CART_INITIAL_STATE,
  reducers: {
    setIsCartOpen(state, action) {
      state.isCartOpen = action.payload;
    },
    addItemToCart(state, action) {
      state.cartItems = addCartItem(state.cartItems, action.payload);
    },
    removeItemFromCart(state, action) {
      state.cartItems = removeCartItem(state.cartItems, action.payload);
    },
    deleteItemFromCart(state, action) {
      state.cartItems = deleteCartItem(state.cartItems, action.payload);
    },
    setShippingInfo(state, action) {
      state.shippingInfo = action.payload;
    },
    resetCart(state) {
      state.cartItems = [];
      state.isCartOpen = false;
      state.shippingInfo = { type: null, price: null };
    },
    setDiscount(state, action) {
      state.discount = action.payload;
    },

    setCompletedOrder(state, action) {
      state.completedOrder = action.payload;
    },
  },
});

export const {
  setIsCartOpen,
  addItemToCart,
  removeItemFromCart,
  deleteItemFromCart,
  setShippingInfo,
  resetCart,
  setDiscount,
  setCompletedOrder,
} = cartSlice.actions;

export const cartReducer = cartSlice.reducer;
