import styled from "styled-components";

export const SwirlSpinnerContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const Swirl = styled.div`
  width: 150px;
  height: 150px;
  position: relative;
  transform: scale(0.7);
  transform: rotate(0deg);

  & div {
    position: absolute;
    border: solid 5px #aa61a5;
    border-radius: 75px 75px 0 0;
    border-bottom: 0;
    box-sizing: border-box;
  }

  & div:nth-of-type(odd) {
    transform: rotate(180deg);
    top: 75px;
  }

  & div:nth-of-type(1) {
    width: 20px;
    height: 10px;
    left: 70px;
  }

  & div:nth-of-type(2) {
    width: 30px;
    height: 15px;
    top: 60px;
    left: 60px;
  }

  & div:nth-of-type(3) {
    width: 40px;
    height: 20px;
    left: 60px;
  }

  & div:nth-of-type(4) {
    width: 50px;
    height: 25px;
    top: 50px;
    left: 50px;
  }

  & div:nth-of-type(5) {
    width: 60px;
    height: 30px;
    left: 50px;
  }

  & div:nth-of-type(6) {
    width: 70px;
    height: 35px;
    top: 40px;
    left: 40px;
  }

  & div:nth-of-type(7) {
    width: 80px;
    height: 40px;
    left: 40px;
  }

  & div:nth-of-type(8) {
    width: 90px;
    height: 45px;
    top: 30px;
    left: 30px;
  }

  & div:nth-of-type(9) {
    width: 100px;
    height: 50px;
    left: 30px;
  }

  & div:nth-of-type(10) {
    width: 110px;
    height: 55px;
    top: 20px;
    left: 20px;
  }

  & div:nth-of-type(11) {
    width: 120px;
    height: 60px;
    left: 20px;
  }

  & div:nth-of-type(12) {
    width: 130px;
    height: 65px;
    top: 10px;
    left: 10px;
  }

  & div:nth-of-type(13) {
    width: 140px;
    height: 70px;
    left: 10px;
  }

  & div:nth-of-type(14) {
    width: 150px;
    height: 75px;
    top: 0;
    left: 0;
  }
  animation: rotation 1s infinite linear;

  @keyframes rotation {
    0% {
      transform: rotate(0deg) scale(0.7);
    }
    50% {
      transform: rotate(180deg) scale(1);
    }
    100% {
      transform: rotate(360deg) scale(0.7);
    }
  }
`;
