import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { updateUserPassword, signOutUser } from "../../utils/firebase.utils";

import "./acct-dialogs.styles.scss";

const wait = () => new Promise((resolve) => setTimeout(resolve, 1000));

function PasswordDialog({ setError, setSuccess }) {
  const navigate = useNavigate();
  const close = <FontAwesomeIcon icon={faXmark} />;
  const penToSquare = <FontAwesomeIcon icon={faPenToSquare} />;
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [open, setOpen] = useState(false);

  const handleFirebaseError = (error) => {
    if (checkFirebaseError(error)) {
      signOutUser();
      navigate("/auth", { state: { from: window.location.pathname } });
    }
  };

  const checkFirebaseError = (error) => {
    const errorCode = error.code;
    return errorCode === "auth/requires-recent-login";
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(null);
    setIsPasswordValid(true);

    if (newPassword.length < 6) {
      setError({
        code: "0500",
        message: "Password should be at least 6 characters long.",
      });
      return;
    }

    if (newPassword === confirmPassword) {
      try {
        const handleUpdatePassword = async () => {
          const message = await updateUserPassword(newPassword);
          if (message instanceof Error) {
            setError(message);
            handleFirebaseError(message);
          } else {
            setSuccess(message);
          }
        };
        handleUpdatePassword();
        window.scrollTo({ top: 0, behavior: "smooth" });
        wait().then(() => setOpen(false));
      } catch (error) {
        console.error("Error updating password:", error);
        setError({ code: "0300", message: "Failed to update password" });
      }
    } else if (newPassword !== confirmPassword) {
      setIsPasswordValid(false);
      setError({ code: "0100", message: `Password doesn't match` });
    }
  };

  const handleSetNewPassword = (e) => {
    setIsPasswordValid(true);
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    if (newPassword === e.target.value) {
      setIsPasswordValid(true);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger className="trigger">Edit {penToSquare}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="overlay" />
        <Dialog.Content className="dialog-content">
          <h2>Update Password</h2>
          <p>You may be redirected to sign in again in order to update this.</p>
          <form onSubmit={handleSubmit}>
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => handleSetNewPassword(e)}
            />
            <input
              placeholder="Confirm New Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => handleConfirmPassword(e)}
              className={!isPasswordValid ? "error" : ""}
            />
            <button className="submit-button" type="submit">
              Update Password
            </button>
          </form>
          <Dialog.Close className="close-button">{close}</Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default PasswordDialog;
