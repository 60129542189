import styled from "styled-components";

export const StyledSuccess = styled.div`
  color: white;
  background-color: var(--success-color);
  padding: 10px;
  border-radius: 5px;
  margin: 25px 0 50px 0;
  font-weight: 600;
  font-size: var(--fs-300);
`;
