import styled from "styled-components";

export const UserAddressCardContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: var(--light-bg);
  border: 1px solid var(--accent-text);
  padding: 10px;
  p {
    padding-block: 5px !important;
  }
`;

export const UserAddressCardOverview = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  p {
    padding-block: 0px;
    margin-block: 5px;
  }
`;
