import { initializeApp } from "firebase/app";
import { setCurrentUser } from "../store/user/user.reducer";

import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile,
  updateEmail,
  updatePassword,
  sendEmailVerification,
} from "firebase/auth";

import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
  collection,
  writeBatch,
  query,
  getDocs,
} from "firebase/firestore";

import { addEmailToList, sendCoupon } from "./printify/printify.utils";

const firebaseConfig = {
  apiKey: "AIzaSyCzTfiQoQN067tUHb6DOQzq-YQCBhqzlYc",

  authDomain: "mellybean-store.firebaseapp.com",

  projectId: "mellybean-store",

  storageBucket: "mellybean-store.appspot.com",

  messagingSenderId: "1098668188571",

  appId: "1:1098668188571:web:d76a8fd23ead16b7b3373e",

  measurementId: "G-V26SQ9QXYS",
};

// Initialize Firebase

const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const auth = getAuth();

// export const signInWithGooglePopup = () =>
//   signInWithPopup(auth, googleProvider);

export const signInWithGooglePopup = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const { user } = result;

    // Check if the user already has a Firestore document
    const userRef = doc(db, "users", user.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      // User does not have a Firestore document, create one
      const { displayName, email } = user;
      const createdAt = new Date();

      await setDoc(userRef, {
        displayName,
        email,
        createdAt,
        orderHistory: [],
        shippingAddress: [],
        newUser: true,
      });

      await addEmailToList(email);
      await sendCoupon(email, displayName);
    }

    return result;
  } catch (error) {
    throw new Error("Failed to sign in with Google.");
  }
};

export const signInWithGoogleRedirect = () =>
  signInWithRedirect(auth, googleProvider);

export const db = getFirestore();

export const getOrdersFromDatabase = async () => {
  const ordersCollectionRef = collection(db, "orders");
  const q = query(ordersCollectionRef);

  const querySnapshot = await getDocs(q);

  const orders = [];

  querySnapshot.forEach((doc) => {
    orders.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return orders;
};

export const addOrderToDatabase = async (orderObject) => {
  const newOrder = {
    orderDate: new Date(),
    ...orderObject,
  };

  const ordersCollectionRef = collection(db, "orders");

  const docRef = await addDoc(ordersCollectionRef, newOrder);

  return docRef.id;
};

export const markOrderAsShipped = async (orderId) => {
  const orderRef = doc(db, "orders", orderId);
  await updateDoc(orderRef, { shipped: true });
};

export const addPurchaseToUserPurchaseHistory = async (
  cartItems,
  shippingFormFields
) => {
  if (!cartItems || !shippingFormFields) return;

  const userDocRef = doc(db, "users", auth.currentUser.uid);

  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) return;

  const orderHistory = userSnapshot.data().orderHistory;

  const newOrder = {
    orderDate: new Date(),
    orderItems: cartItems,
  };

  let updatedShippingAddress = userSnapshot.data().shippingAddress || [];

  // check if the shippingFormFields already match an address in the user's shippingAddress array. If there is no match, add the new address to the array.

  const addressIndex = updatedShippingAddress.findIndex((address) => {
    return compareAddresses(address, shippingFormFields);
  });

  if (addressIndex === -1) {
    // Add the new address to the array
    updatedShippingAddress.push(shippingFormFields);
  }

  await setDoc(userDocRef, {
    ...userSnapshot.data(),
    orderHistory: [...orderHistory, newOrder],
    shippingAddress: updatedShippingAddress,
  });
};

export const getUserShippingAddress = async () => {
  if (!auth.currentUser) return;

  const userDocRef = doc(db, "users", auth.currentUser.uid);

  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) return;

  return userSnapshot.data().shippingAddress;
};

export const createUserDocFromAuth = async (
  dispatch,
  userAuth,
  additionalInfo = {}
) => {
  if (!userAuth) return;

  const userDocRef = doc(db, "users", userAuth.uid);

  const userSnapshot = await getDoc(userDocRef);

  if (userSnapshot.exists() && !userSnapshot.data().displayName) {
    const snapshotDisplayName = userSnapshot.data().displayName;

    if (snapshotDisplayName !== userAuth.displayName) {
      try {
        await updateProfile(userAuth, {
          ...userAuth,
          displayName: snapshotDisplayName,
        });
      } catch (error) {
        console.log("Error updating the profile:", error);
      }
    }

    await userAuth.reload();
  }

  if (!userSnapshot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        orderHistory: [],
        shippingAddress: [],
        newUser: true,
        ...additionalInfo,
      });

      const newUserSnapshot = await getDoc(userDocRef);

      dispatch(
        setCurrentUser({
          id: newUserSnapshot.id,
          ...newUserSnapshot.data(),
        })
      );
    } catch (error) {
      console.log("error creating the user", error.message);
    }
  }

  return userDocRef;
};

export const createAuthUserWithEmailAndPassword = async (
  email,
  password,
  displayName
) => {
  if (!email || !password) return;

  try {
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const { user } = response;

    const userDocRef = doc(db, "users", auth.currentUser.uid);

    const userSnapshot = await getDoc(userDocRef);

    if (!userSnapshot.exists()) {
      // Update displayName field in Firebase Authentication
      await updateProfile(auth.currentUser, {
        ...auth.currentUser,
        displayName: displayName,
      });

      // Update displayName field in Firestore document
      await updateDoc(userDocRef, { displayName: displayName });

      await auth.currentUser.reload();
    }

    await sendEmailVerification(user);
    await addEmailToList(email);
    await sendCoupon(email, displayName);

    return response;
  } catch (error) {
    throw new Error("Failed to create user.");
  }
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => {
  await signOut(auth);
};

export const onAuthStateChangedListener = (callback) => {
  onAuthStateChanged(auth, callback);
};

export const updateUserEmail = async (newEmail) => {
  try {
    if (!auth.currentUser) {
      throw new Error("User not authenticated.");
    }

    // Update email address in Firebase Authentication
    await updateEmail(auth.currentUser, newEmail);

    // Update email field in Firestore user document
    const userDocRef = doc(db, "users", auth.currentUser.uid);

    const userSnapshot = await getDoc(userDocRef);

    if (!userSnapshot.exists()) {
      throw new Error("User document not found.");
    }
    await updateDoc(userDocRef, { email: newEmail });

    await auth.currentUser.reload();
    return "Email updated successfully.";
  } catch (error) {
    return error;
  }
};

export const addShippingAddressToUserDoc = async (shippingFormFields) => {
  try {
    if (!auth.currentUser) {
      throw new Error("User not authenticated.");
    }

    const userDocRef = doc(db, "users", auth.currentUser.uid);

    const userSnapshot = await getDoc(userDocRef);

    if (!userSnapshot.exists()) {
      throw new Error("User document not found.");
    }

    const updatedShippingAddress = userSnapshot.data().shippingAddress || [];

    if (updatedShippingAddress.length > 0) {
      updatedShippingAddress.unshift(shippingFormFields);
    } else {
      updatedShippingAddress.push(shippingFormFields);
    }

    await updateDoc(userDocRef, { shippingAddress: updatedShippingAddress });
    await auth.currentUser.reload();

    return "Shipping address updated successfully.";
  } catch (error) {
    return error;
  }
};

export const deleteShippingAddress = async (userAddress) => {
  try {
    if (!auth.currentUser) {
      throw new Error("User not authenticated.");
    }

    const userDocRef = doc(db, "users", auth.currentUser.uid);

    const userSnapshot = await getDoc(userDocRef);

    if (!userSnapshot.exists()) {
      throw new Error("User document not found.");
    }

    const shippingAddressArray = userSnapshot.data().shippingAddress || [];

    const addressIndex = shippingAddressArray.findIndex((address) => {
      return compareAddresses(address, userAddress);
    });

    if (addressIndex === -1) {
      throw new Error("User address not found in the database.");
    }

    shippingAddressArray.splice(addressIndex, 1);

    await updateDoc(userDocRef, { shippingAddress: shippingAddressArray });
    await auth.currentUser.reload();

    return "Shipping address deleted successfully.";
  } catch (error) {
    return error;
  }
};

export const makeDefaultShippingAddress = async (userAddress) => {
  try {
    if (!auth.currentUser) {
      throw new Error("User not authenticated.");
    }

    const userDocRef = doc(db, "users", auth.currentUser.uid);

    const userSnapshot = await getDoc(userDocRef);

    if (!userSnapshot.exists()) {
      throw new Error("User document not found.");
    }

    const shippingAddressArray = userSnapshot.data().shippingAddress || [];

    const addressIndex = shippingAddressArray.findIndex((address) => {
      return compareAddresses(address, userAddress);
    });

    if (addressIndex === -1) {
      throw new Error("User address not found in the database.");
    }

    // Swap the address with the address at index 0
    const defaultAddress = shippingAddressArray[0];
    shippingAddressArray[0] = shippingAddressArray[addressIndex];
    shippingAddressArray[addressIndex] = defaultAddress;

    await updateDoc(userDocRef, { shippingAddress: shippingAddressArray });
    await auth.currentUser.reload();

    return "Shipping address updated successfully.";
  } catch (error) {
    return error;
  }
};

// Helper function to compare address objects
function compareAddresses(address1, address2) {
  return (
    address1.address1 === address2.address1 &&
    address1.address2 === address2.address2 &&
    address1.city === address2.city &&
    address1.zip === address2.zip &&
    address1.phone === address2.phone &&
    address1.email === address2.email &&
    address1.country.value === address2.country.value &&
    address1.state.value === address2.state.value &&
    address1.first_name === address2.first_name &&
    address1.last_name === address2.last_name
  );
}

export const getUpdatedSnapshot = async () => {
  try {
    if (!auth.currentUser) {
      throw new Error("User not authenticated.");
    }

    const userDocRef = doc(db, "users", auth.currentUser.uid);

    const userSnapshot = await getDoc(userDocRef);

    if (!userSnapshot.exists()) {
      throw new Error("User document not found.");
    }

    return userSnapshot.data();
  } catch (error) {
    return error;
  }
};

export const updateUserPassword = async (newPassword) => {
  try {
    if (!auth.currentUser) {
      throw new Error("User not authenticated.");
    }
    // Update password in Firebase Authentication
    await updatePassword(auth.currentUser, newPassword);

    await auth.currentUser.reload();
    return "Password updated successfully.";
  } catch (error) {
    return error;
  }
};

export const updateUserDisplayName = async (newDisplayName) => {
  try {
    if (!auth.currentUser) {
      throw new Error("User not authenticated.");
    }

    const userDocRef = doc(db, "users", auth.currentUser.uid);

    const userSnapshot = await getDoc(userDocRef);

    if (!userSnapshot.exists()) {
      throw new Error("User document not found.");
    }

    // Update displayName field in Firebase Authentication
    await updateProfile(auth.currentUser, {
      ...auth.currentUser,
      displayName: newDisplayName,
    });

    // Update displayName field in Firestore document
    await updateDoc(userDocRef, { displayName: newDisplayName });

    await auth.currentUser.reload();

    return "Display name updated successfully.";
  } catch (error) {
    return error;
  }
};

//* check if user is new, and eligible for signup discount...
export const getNewUser = async () => {
  if (!auth.currentUser) return;

  const userDocRef = doc(db, "users", auth.currentUser.uid);

  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) return;

  return userSnapshot.data().newUser;
};

//* set newUser to false after signup discount has been applied
export const setNewUser = async () => {
  if (!auth.currentUser) return;

  const userDocRef = doc(db, "users", auth.currentUser.uid);

  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) return;

  await updateDoc(userDocRef, { newUser: false });
  await auth.currentUser.reload();
};
