import { SwirlSpinnerContainer, Swirl } from "./swirl-spinner.styles";

function SwirlSpinner() {
  return (
    <SwirlSpinnerContainer>
      <Swirl>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Swirl>
    </SwirlSpinnerContainer>
  );
}

export default SwirlSpinner;
