import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentUser } from "../../../store/user/user.selector";
import { setCurrentUser } from "../../../store/user/user.reducer";
import {
  getUserShippingAddress,
  getUpdatedSnapshot,
} from "../../../utils/firebase.utils";
import { Helmet } from "react-helmet";

import EmailDialog from "../../acct-dialogs/email-dialog.component";
import PasswordDialog from "../../acct-dialogs/password-dialog.component";
import DisplayNameDialog from "../../acct-dialogs/display-name-dialog.component";
import ShippingDialog from "../../acct-dialogs/shipping-dialog";
import UserAddressCard from "../../../components/user-address-card/user-address-card.component";

import Error from "../../error/error.component";
import Success from "../../success/success.component";
import SwirlSpinner from "../../swirl-spinner/swirl-spinner.component";

import {
  AccountContainer,
  EmailContainer,
  PasswordContainer,
  UsernameContainer,
  AddressContainer,
  EditButton,
  AddressCardsContainer,
} from "./account.styles";

function Account() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);
  const [userAddressArray, setUserAddressArray] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUser, setIsUser] = useState(false);
  const [isTimeoutExpired, setIsTimeoutExpired] = useState(false);

  useEffect(() => {
    if (currentUser !== null) {
      setIsUser(true);
      setIsLoading(false);
    } else {
      const timeout = setTimeout(() => {
        setIsTimeoutExpired(true);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      getUserShippingAddress().then((address) => {
        setUserAddressArray(address);
      });
    }
  }, [currentUser, success, error]);

  useEffect(() => {
    const checkSignInMethod = async () => {
      if (currentUser) {
        const providerData = currentUser.providerData;
        const googleCheck = providerData?.some(
          (provider) => provider.providerId === "google.com"
        );
        if (googleCheck) {
          setIsGoogleSignIn(true);
        }
      }
    };

    checkSignInMethod();
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      const handleUpdateUser = async () => {
        const data = await getUpdatedSnapshot();
        if (data instanceof Error) {
          setError(data);
        } else {
          dispatch(setCurrentUser(data));
        }
      };
      handleUpdateUser();
    }
  }, [success]);

  return isLoading ? (
    <>
      <Helmet>
        <title>Mellybean | Account</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      {isTimeoutExpired && <Navigate to="/auth" replace={true} />}
      <SwirlSpinner />
    </>
  ) : (
    <>
      {!isUser || (!currentUser && <Navigate to="/" replace={true} />)}
      <AccountContainer className="wrapper">
        <h1>Account Overview</h1>
        {error && <Error error={error} />}
        {success && <Success message={success} />}
        <EmailContainer>
          <p>
            <span>Login/Email:</span> {currentUser?.email}
          </p>
          {!isGoogleSignIn && (
            <EmailDialog
              currentUser={currentUser}
              setSuccess={setSuccess}
              setError={setError}
            />
          )}
        </EmailContainer>
        {!isGoogleSignIn && (
          <PasswordContainer>
            <p>
              <span>Password:</span> ********
            </p>

            <PasswordDialog setSuccess={setSuccess} setError={setError} />
          </PasswordContainer>
        )}
        <UsernameContainer>
          <p>
            <span>Display Name:</span> {currentUser?.displayName}
          </p>

          {!isGoogleSignIn && (
            <DisplayNameDialog
              currentUser={currentUser}
              setSuccess={setSuccess}
              setError={setError}
            />
          )}
        </UsernameContainer>
        <AddressContainer>
          <p>
            <span>Shipping Info:</span>
          </p>
          {userAddressArray?.length === 0 && (
            <p className="notification">
              Looks like you haven't saved a shipping address. Add one now to
              make checkout easier.
            </p>
          )}
          <ShippingDialog
            currentUser={currentUser}
            setSuccess={setSuccess}
            setError={setError}
          />
          {userAddressArray?.length > 0 && (
            <AddressCardsContainer>
              {userAddressArray.map((userAddress, i) => (
                <UserAddressCard
                  userAddress={userAddress}
                  index={i}
                  setError={setError}
                  setSuccess={setSuccess}
                  key={i}
                />
              ))}
            </AddressCardsContainer>
          )}
        </AddressContainer>
      </AccountContainer>
    </>
  );
}

export default Account;
