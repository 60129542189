import styled from "styled-components";

export const StyledError = styled.div`
  color: white;
  background-color: #c73c3c;
  padding: 10px;
  border-radius: 5px;
  margin: 25px 0 50px 0;
  font-weight: 600;
  font-size: var(--fs-300);
`;
