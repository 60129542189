import { createSelector } from "reselect";

// export const selectCurrentUser = (state) => state.user.currentUser;

const selectUserReducer = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUserReducer],
  (user) => user.currentUser
);

export const selectShowPromotionModal = createSelector(
  [selectUserReducer],
  (user) => user.showPromotionModal
);
