import { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import {
  deleteShippingAddress,
  makeDefaultShippingAddress,
} from "../../utils/firebase.utils";

import { UserAddressCardOverview } from "../user-address-card/user-address-card.styles.jsx";

import "./acct-dialogs.styles.scss";

const wait = () => new Promise((resolve) => setTimeout(resolve, 1000));

function AddressCardDialog({ userAddress, setError, setSuccess }) {
  const close = <FontAwesomeIcon icon={faXmark} />;
  const penToSquare = <FontAwesomeIcon icon={faPenToSquare} />;
  const [open, setOpen] = useState(false);

  const {
    first_name,
    last_name,
    phone,
    address1,
    address2,
    city,
    state,
    country,
    zip,
  } = userAddress;

  const handleDelete = (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(null);
    const handleDeleteAddress = async (userAddress) => {
      const message = await deleteShippingAddress(userAddress);

      if (message instanceof Error) {
        setError(message);
      } else {
        setSuccess(message);
      }
    };
    handleDeleteAddress(userAddress);
    window.scrollTo({ top: 0, behavior: "smooth" });
    wait().then(() => setOpen(false));
  };

  const handleMakeDefault = (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(null);
    const handleChangeDefaultAddress = async (userAddress) => {
      const message = await makeDefaultShippingAddress(userAddress);

      if (message instanceof Error) {
        setError(message);
      } else {
        setSuccess(message);
      }
    };
    handleChangeDefaultAddress(userAddress);

    window.scrollTo({ top: 0, behavior: "smooth" });
    wait().then(() => setOpen(false));
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger className="trigger">Edit {penToSquare}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="overlay" />
        <Dialog.Content className="dialog-content">
          <UserAddressCardOverview>
            <p>
              <span>Name:</span> {first_name} {last_name}
            </p>
            <p>
              <span>Phone Number:</span> {phone}
            </p>
            <p>
              <span>Address:</span> {address1}
            </p>
            {address2 && (
              <p>
                <span>Address Details (Optional):</span> {address2}
              </p>
            )}
            <p>
              <span>City:</span> {city}
            </p>
            <p>
              <span>{country.value === "US" ? "State:" : "Province:"}</span>{" "}
              {state.label}
            </p>
            <p>
              <span>Country:</span> {country.label}
            </p>
            <p>
              <span>Zip Code:</span> {zip}
            </p>
          </UserAddressCardOverview>
          <Dialog.Close className="close-button">{close}</Dialog.Close>
          <div className="dialog-buttons-container">
            <button
              aria-label="Make this address default"
              className="default-button"
              onClick={handleMakeDefault}
            >
              Make Default
            </button>
            <button
              aria-label="Delete this address"
              className="delete-button"
              onClick={handleDelete}
            >
              Delete
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default AddressCardDialog;
